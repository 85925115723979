import React from 'react'
import HoverIcon from '../HoverIcon';
import { StaticImage } from 'gatsby-plugin-image';
import { animateScroll as scroll } from 'react-scroll';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-padding">
        <div className="footer__logo">
          <HoverIcon onClick={scroll.scrollToTop}>M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z</HoverIcon>
          <StaticImage
            src="../../images/logo.png"
            alt="Academia Swim Gym"
            placeholder="blurred"
            className="footer__logo__image aligncenter"
          />
        </div>
        <div className="footer__flex">
          <div className="footer__flex__coluna">
            <HoverIcon>M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z</HoverIcon>
            <h5 className="footer__flex__coluna--1">
              (17) 3223-7372
            </h5>
            <h5 className="footer__flex__coluna--2">
              (17) 3121-6521
            </h5>
            <div className="flex">
           
              <HoverIcon>M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z</HoverIcon>  <h5>(17) 98128-6601</h5>
            
            </div>
          </div>
          <div className="footer__flex__coluna">
            <HoverIcon>M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z</HoverIcon>
            <h5 className="footer__flex__coluna--1">Av. Nadima Damha, 2035 - Jardim Yolanda, São José do Rio Preto - SP, 15061-759 - Em frente à Represa Municipal</h5>

          </div>
          <div className="footer__flex__coluna">
            <HoverIcon>M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z</HoverIcon>
            <h5 className="footer__flex__coluna--1"><a href="mailto:academiaswimgym@hotmail.com.br" className="link-email">academiaswimgym@hotmail.com.br</a></h5>
          </div>
        </div>
      </div>
      <div>
        <iframe className="footer__mapa" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14918.227464259984!2d-49.35351!3d-20.809208!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x22dbf9e40d609a7a!2sAcademia+Swim+Gym!5e0!3m2!1spt-BR!2sbr!4v1503191955917" frameBorder="0" allowFullScreen width="100%" height="100%" title="mapa"></iframe>
      </div>
      <div className="footer__info">
        <a href="http://eccjr.com.br/">Desenvolvido com ♥ pela ECCjr © 2020</a>
      </div>
      
    </footer>
  )
}